'use client';
import { AppContext } from '@/app/appProvider';
import cn from 'classnames';
import { useTranslations } from 'next-intl';
import { useContext } from 'react';
import { Col, Container, Row } from 'reactstrap';
import styles from './Footer.module.scss';
import HelpfulLinks from './HelpfulLinks';
import ContactUs from './ContactUs';

type Props = {
  dark?: boolean;
};

export default function Footer({ dark }: Props) {
  const { retailerConfig, displayPhoneNumber } = useContext(AppContext);
  const t = useTranslations('components.footer');
  const classNames = cn(styles.mainFooter, dark ? styles.mainFooterDark : '');
  const disclaimer = t.rich('disclaimer', { p: (chunk) => <p>{chunk}</p> });

  return (
    <footer className={classNames}>
      <Container>
        <Row className="mt-4">
          <Col sm={16} lg={8}>
            <HelpfulLinks />
          </Col>
          <Col sm={16} lg={8} className={styles.mainFooterRightColumn}>
            <ContactUs
              phoneNumber={displayPhoneNumber}
              emailAddress={retailerConfig?.emailAddress}
            />
          </Col>
          {disclaimer && (
            <Row className="mt-4">
              <Col sm={16} lg={16}>
                {disclaimer}
              </Col>
            </Row>
          )}
        </Row>
        <Row className="mt-4 pb-4 text-body-tertiary">
          <Col sm={16} lg={8}>
            {t('copyright', { currentYear: new Date().getFullYear() })}
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
