import { formatPhoneNumberWithParens } from '@/app/utils/helpers';
import { ByPhoneOutline } from '../../icons/CustomIconPack';
import CustomLink from '../CustomLink';
import { TextDecorationEnum } from '@/app/utils/constants';
import { useTranslations } from 'next-intl';
import { Row } from 'reactstrap';

type Props = {
  phoneNumber?: string;
  emailAddress?: string;
};

export default function ContactUs({ phoneNumber, emailAddress }: Props) {
  const t = useTranslations('components.footer');

  return (
    <>
      {phoneNumber && (
        <Row>
          <small className="mb-1 text-body-tertiary">{t('callUs')}</small>
          <CustomLink textDecoration={TextDecorationEnum.none} href={`tel:+1${phoneNumber}`}>
            <ByPhoneOutline /> {formatPhoneNumberWithParens(phoneNumber)}
          </CustomLink>
        </Row>
      )}
      {emailAddress && (
        <Row>
          <CustomLink textDecoration={TextDecorationEnum.none} href={`mailto:${emailAddress}`}>
            {emailAddress}
          </CustomLink>
        </Row>
      )}
    </>
  );
}
