import { TextDecorationEnum } from '@/app/utils/constants';
import CustomLink from '../CustomLink';
import styles from './Footer.module.scss';
import { getRetailerConfig } from '@/app/services/retailerService';
import { useContext } from 'react';
import { AppContext } from '@/app/appProvider';
import { useLocale, useTranslations } from 'next-intl';
import { getFooterLink } from '@/app/config/footer/footerLinks';

export default function HelpfulLinks() {
  const appContext = useContext(AppContext);
  const retailerConfig = getRetailerConfig(appContext.srcPartnerId);
  const locale = useLocale();
  const t = useTranslations('components.footer');

  return (
    <div>
      <small className="fw-bolder text-uppercase mb-1">{t('helpfulLinks')}</small>
      <ul className={styles.linksList}>
        <li>
          <CustomLink textDecoration={TextDecorationEnum.none} href="https://get.adobe.com/reader/">
            {t('adobeReader')}
          </CustomLink>
        </li>
        {retailerConfig?.accessibilityStatement && (
          <li>
            <CustomLink
              textDecoration={TextDecorationEnum.none}
              href={retailerConfig.accessibilityStatement}
            >
              {t('accessibilityStatement')}
            </CustomLink>
          </li>
        )}
        {retailerConfig?.footerLinks && (
          <>
            {getFooterLink('accessibilityStatement', locale, retailerConfig.footerLinks) && (
              <li>
                <CustomLink
                  textDecoration={TextDecorationEnum.none}
                  href={getFooterLink('accessibilityStatement', locale, retailerConfig.footerLinks)}
                >
                  {t('accessibilityStatement')}
                </CustomLink>
              </li>
            )}
            {getFooterLink('policy', locale, retailerConfig.footerLinks) && (
              <li>
                <CustomLink
                  textDecoration={TextDecorationEnum.none}
                  href={getFooterLink('policy', locale, retailerConfig.footerLinks)}
                >
                  {t('privacyAndLicensing')}
                </CustomLink>
              </li>
            )}
            {getFooterLink('caPolicy', locale, retailerConfig.footerLinks) && (
              <li>
                <CustomLink
                  textDecoration={TextDecorationEnum.none}
                  href={getFooterLink('caPolicy', locale, retailerConfig.footerLinks)}
                >
                  {t('californiaPrivacyPolicy')}
                </CustomLink>
              </li>
            )}
            {getFooterLink('terms', locale, retailerConfig.footerLinks) && (
              <li>
                <CustomLink
                  textDecoration={TextDecorationEnum.none}
                  href={getFooterLink('terms', locale, retailerConfig.footerLinks)}
                >
                  {t('termsAndConditions')}
                </CustomLink>
              </li>
            )}
          </>
        )}
      </ul>
    </div>
  );
}
